import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { normalizedData } from "@utils";
import Seo from "@components/seo";
import Layout from "@layout";
import Header from "@layout/header/layout-02";
import Footer from "@layout/footer/layout-01";
import PageHeader from "@containers/page-header/layout-02";
import ContactArea from "@containers/contact/layout-02";
import { Container } from "react-bootstrap";

const DataManagement = ({ pageContext, location, data }) => {
    const content = normalizedData(data?.page?.content || []);
    const globalContent = normalizedData(data?.allGeneral.nodes || []);

    return (
        <Layout location={location}>
            <Seo titleTemplate="TEQT" title="Data Management"
                description="We have successfully completed $40,000,000 worth of digital marketing projects for more than 5,000 clients in more than 120 countries." />
            <Header
                data={{
                    ...globalContent["header"],
                    ...globalContent["menu"],
                    socials: data.site.siteMetadata.socials,
                }}
            />
            <main className="site-wrapper-reveal">
                <PageHeader
                    pageContext={pageContext}
                    location={location}
                    title="IT outsourcing company"
                />
                <div className="service-container">
                    <Container className="mb-5">
                        <p className="mt-5">TEQT is one of the leaders in Data Management, providing a suite of offerings including in-house built accelerators, deep technology partnerships, highly trained and experienced professionals, and a wide variety of client experience.</p>
                        <p className="mt-2">We have delivered millions of dollar savings, significant reduction in data processing times which in some cases are over 90% and strong governance for scalability across markets and divisions for clients in BFS, Insurance and Travel.</p>
                        <p className="mt-2">Data management refers to the processes and activities involved in the collection, organization, storage, retrieval, and use of data. Effective data management is critical for businesses, organizations, and individuals who rely on data to make informed decisions, gain insights, and achieve their objectives.</p>
                        <p className="mt-2">Data management involves the following stages:</p>

                        <ul className="circle-list">
                            <li className="mt-2"> <span className="text-black">Data Collection: – </span> This involves gathering relevant data from different sources, such as surveys, customer feedback, social media, and other online and offline sources.</li>
                            <li className="mt-2"><span className="text-black">Data Organization:  –</span> This involves sorting, categorizing, and structuring data in a way that makes it easy to analyze and use. This stage involves creating data models, schemas, and taxonomies.</li>
                            <li className="mt-2"><span className="text-black">Data Storage – </span>This involves choosing an appropriate storage system, such as a database, data warehouse, or cloud storage, and ensuring that the data is secure and accessible to authorized users.</li>

                            <li className="mt-2"><span className="text-black">Data Retrieval  – </span>This involves retrieving data when needed and ensuring that it is accurate and up-to-date. This stage involves creating queries and reports to extract data from the storage system.</li>

                            <li className="mt-2"><span className="text-black">Data Use  – </span>This involves analyzing, visualizing, and using the data to gain insights, make informed decisions, and achieve organizational goals.</li>
                        </ul>

                        <p className="mt-3">There are several tools and technologies that support data management, including:</p>

                        <ul className="circle-list">
                            <li className="mt-2"> Big Data Technologies that enable the storage, processing, and analysis of large and complex data sets, such as Hadoop, Spark, and NoSQL databases.</li>
                            <li className="mt-2">Database Management Systems (DBMS)</li>
                            <li className="mt-2">Data Warehousing</li>
                            <li className="mt-2">Business Intelligence (BI) Tools</li>
                            <li className="mt-2">Data Integration Tools</li>
                        </ul>
                    </Container>
                </div>
                <ContactArea data={content["contact-section"]} />
            </main>
            <Footer data={{ ...data.site.siteMetadata }} />
        </Layout>
    );
};

export const query = graphql`
    query DataManagementPageQuery {
        allGeneral {
            nodes {
                section
                ...HeaderTwo
            }
        }
        site {
            ...Site
        }
        page(title: { eq: "it-solutions" }, pageType: { eq: "innerpage" }) {
            content {
                ...PageContent
            }
        }
        allItSolution {
            nodes {
                ...ItSolutionThree
            }
        }
    }
`;

DataManagement.propTypes = {
    pageContext: PropTypes.shape({}),
    location: PropTypes.shape({}),
    data: PropTypes.shape({
        allGeneral: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        site: PropTypes.shape({
            siteMetadata: PropTypes.shape({
                contact: PropTypes.shape({}),
            }),
        }),
        page: PropTypes.shape({
            content: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        allItSolution: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
    }),
};

export default DataManagement;
